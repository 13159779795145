const DetalleAyuntamiento = () => import('@/app/views/ayuntamientos/views/DetalleAyuntamiento');
const ListadoAyuntamientos = () => import('@/app/views/ayuntamientos/views/ListadoAyuntamientos');
const NuevoAyuntamiento = () => import('@/app/views/ayuntamientos/views/NuevoAyuntamiento');

const ayuntamientosRoutes = [
  {
    path: 'ayuntamientos',
    redirect: '/ayuntamientos',
    name: 'Ayuntamientos',
    component: {
      render(c) {
        return c('router-view');
      }
    },
    children: [
      {
        path: '',
        name: 'Listado',
        component: ListadoAyuntamientos,
        meta: {
          auth: true,
          entidad: 'ayuntamientos',
          tipo: 'listar'
        }
      },
      {
        path: '/ayuntamientos',
        name: '',
        component: NuevoAyuntamiento,
        meta: {
          auth: true,
          entidad: 'ayuntamientos',
          tipo: 'crear'
        },
        children: [
          {
            path: '/ayuntamientos/nuevo',
            name: 'Crear',
            meta: {
              auth: true,
              entidad: 'ayuntamientos',
              tipo: 'crear'
            }
          }
        ]
      },
      {
        path: '/ayuntamientos',
        name: '',
        component: DetalleAyuntamiento,
        meta: {
          auth: true,
          entidad: 'ayuntamientos',
          tipo: 'listar'
        },
        children: [
          {
            path: '/ayuntamientos/:id',
            name: 'Detalle',
            meta: {
              auth: true,
              entidad: 'ayuntamientos',
              tipo: 'listar'
            }
          }
        ]
      }
    ]
  }
];

export default ayuntamientosRoutes;

