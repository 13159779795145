import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Vue from 'vue'
import CoreuiVuePro from '@coreui/vue-pro/src/index.js'
import App from './App'
import router from '@/app/router/index'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import moment from 'moment';
import '@/app/shared/utils/filters';

//components
import Toast from "@/app/shared/components/Toast";
import DeleteModal from '@/app/shared/components/modals/DeleteModal';
import SpinnerTtt from '@/app/shared/components/SpinnerTtt';
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

Vue.component('toast', Toast);
Vue.component('DeleteModal', DeleteModal);
Vue.component('SpinnerTtt', SpinnerTtt);
Vue.component('multiselect', Multiselect);
Vue.component('date-picker', DatePicker);

Vue.use(CoreuiVuePro)
Vue.prototype.$log = console.log.bind(console)

moment.locale('es');
window.moment = moment;

new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  }
})
