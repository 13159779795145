import { ADMIN_API } from "@/config";

export const apiGlobalConstants = {
  admin: ADMIN_API,
  usuario: `${ADMIN_API}/usuarios`,
  vado: `${ADMIN_API}/vados`,
  consulta: `${ADMIN_API}/consultas`,
  visita: `${ADMIN_API}/visitas`,
  variable: `${ADMIN_API}/variables`,
  logs: `${ADMIN_API}/logs`,
  ayuntamiento: `${ADMIN_API}/ayuntamientos`,
  estado: `${ADMIN_API}/estados`,
  tarea: `${ADMIN_API}/tareas`
}
