const ListadoLogActividad = () => import('@/app/views/configuracion/log-actividad/views/ListadoLogActividad');

const logActividadRoutes = [
  {
    path: 'log-actividad',
    redirect: '/configuracion/log-actividad',
    name: 'Log de Actividad',
    component: {
      render(c) {
        return c('router-view');
      }
    },
    children: [
      {
        path: '',
        name: 'Listado',
        component: ListadoLogActividad,
        meta: {
          auth: true,
          entidad: 'log',
          tipo: 'listar'
        }
      }
    ]
  }
];

export default logActividadRoutes;
