const ListadoVariables = () => import('@/app/views/configuracion/variables/views/ListadoVariables');

const variablesRoutes = [
  {
    path: 'variables',
    redirect: '/configuracion/variables',
    name: 'Variables',
    component: {
      render(c) {
        return c('router-view');
      }
    },
    children: [
      {
        path: '',
        name: 'Listado',
        component: ListadoVariables,
        meta: {
          auth: true,
          entidad: 'variables',
          tipo: 'listar'
        }
      }
    ]
  }
];

export default variablesRoutes;
