import Vue from 'vue';


Vue.filter('DateSimple', function (value) {
  if (value) {
    let date = new Date(value)
    let day = date.getDate()
    let m = date.getMonth() + 1;
    let year = date.getFullYear();
    let mes = (m < 10) ? '0' + m : m;
    let dia = (day < 10) ? '0' + day : day;

    return `${dia}/${mes}/${year}`
  } else {
    return '-'
  }
})

Vue.filter('Timeh:m:s', function (value) {
  if (value) {
    let date = new Date(value)
    let hour = date.getHours()
    let minutes = date.getMinutes()
    let seconds = date.getSeconds()

    let minutos = (minutes < 10) ? '0' + minutes : minutes
    let segundos = (seconds < 10) ? '0'+ seconds : seconds

    return `${hour}:${minutos}:${segundos}`
  } else {
    return '-'
  }
})

Vue.filter('DateTimeDMYhms', function (value) {
  if (value) {
    let date = new Date(value)
    let day = date.getDate()
    let year = date.getFullYear()
    let m = date.getMonth() + 1;

    let mes = (m < 10) ? '0' + m : m;
    let dia = (day < 10) ? '0' + day : day;

    let hour = date.getHours()
    let minutes = date.getMinutes()
    let seconds = date.getSeconds()

    let minutos = (minutes < 10) ? '0' + minutes : minutes
    let segundos = (seconds < 10) ? '0'+ seconds : seconds

    return `${dia}/${mes}/${year} ${hour}:${minutos}:${segundos}`
  } else {
    return '-'
  }
})
