import { httpClient, setResponseType } from '@/app/shared/http-service';
import { apiGlobalConstants } from '../config';

const URL = apiGlobalConstants.admin;


const downloadExcel = (modelo, filename) => {
  setResponseType('blob'); // arraybuffer
  return httpClient.get(`${URL}/${modelo}/to-excel?name=${filename}`).then(res => {
    setResponseType('json');
    return res
  });
};

const getPermisos = () => {
  return httpClient.get(`${URL}/permisos`).then(res => res.data);
}


export {
  downloadExcel,
  getPermisos
}
