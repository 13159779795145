const DetalleConsulta = () => import('@/app/views/consultas/views/DetalleConsulta');
const ListadoConsultas = () => import('@/app/views/consultas/views/ListadoConsultas');

const consultasRoutes = [
  {
    path: 'consultas',
    redirect: '/gestion/consultas',
    name: 'Consultas',
    component: {
      render(c) {
        return c('router-view');
      }
    },
    children: [
      {
        path: '',
        name: 'Listado',
        component: ListadoConsultas,
        meta: {
          auth: true,
          entidad: 'consultas',
          tipo: 'listar'
        }
      },
      {
        path: '/gestion/consultas',
        name: '',
        component: DetalleConsulta,
        meta: {
          auth: true,
          entidad: 'consultas',
          tipo: 'listar'
        },
        children: [
          {
            path: '/gestion/consultas/:id',
            name: 'Detalle',
            meta: {
              auth: true,
              entidad: 'consultas',
              tipo: 'listar'
            }
          }
        ]
      }
    ]
  }
];

export default consultasRoutes;
