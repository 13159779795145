const DetalleVado = () => import('@/app/views/vados/views/DetalleVado');
const ListadoVados = () => import('@/app/views/vados/views/ListadoVados');
const NuevoVado = () => import('@/app/views/vados/views/NuevoVado');

const vadosRoutes = [
  {
    path: 'vados',
    redirect: '/gestion/vados',
    name: 'Vados',
    component: {
      render(c) {
        return c('router-view');
      }
    },
    children: [
      {
        path: '',
        name: 'Listado',
        component: ListadoVados,
        meta: {
          auth: true,
          entidad: 'vados',
          tipo: 'listar'
        }
      },
      {
        path: '/gestion/vados',
        name: '',
        component: NuevoVado,
        meta: {
          auth: true,
          entidad: 'vados',
          tipo: 'crear'
        },
        children: [
          {
            path: '/gestion/vados/nuevo',
            name: 'Crear',
            meta: {
              auth: true,
              entidad: 'vados',
              tipo: 'crear'
            }
          }
        ]
      },
      {
        path: '/gestion/vados',
        name: '',
        component: DetalleVado,
        meta: {
          auth: true,
          entidad: 'vados',
          tipo: 'listar'
        },
        children: [
          {
            path: '/gestion/vados/:id',
            name: 'Detalle',
            meta: {
              auth: true,
              entidad: 'vados',
              tipo: 'listar'
            }
          }
        ]
      }
    ]
  }
];

export default vadosRoutes;
