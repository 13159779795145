const ListadoVisitas = () => import('@/app/views/visitas/views/ListadoVisitas');

const visitasRoutes = [
  {
    path: 'visitas',
    redirect: '/gestion/visitas',
    name: 'Visitas',
    component: {
      render(c) {
        return c('router-view');
      }
    },
    children: [
      {
        path: '',
        name: 'Listado',
        component: ListadoVisitas,
        meta: {
          auth: true
        }
      },
    ]
  }
];

export default visitasRoutes;
