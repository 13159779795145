export const BASE_URL = `${process.env.VUE_APP_API_DOMAIN}${process.env.VUE_APP_API_PATH}`; // domain/api
export const ADMIN_API = `${process.env.VUE_APP_API_DOMAIN}${process.env.VUE_APP_API_PATH}${process.env.VUE_APP_API_ADMIN}`; // domain/api/admin
export const GMAPS_KEY = `${process.env.VUE_APP_GMAPS_KEY}`;

export const DOMAIN = process.env.VUE_APP_API_DOMAIN;

// TODO: Coger de la bbdd? => Crear Controllers
export const ESTADOS_VADOS = [
  { value: '3', label : 'Vigente'},
  { value: '4', label : 'No Vigente'},
  { value: '5', label : 'Baja'}
];
export const TIPOS_USUARIO = [
  { value: '1', label : "Admin"},
  { value: '2', label : "Admin_Ayuntamiento"},
  { value: '3', label : "User_Ayuntamiento"}
];
export const TIPOS_USUARIO_AYUNT = [
  { value: '2', label : "Administrador"},
  { value: '3', label : "Usuario"}
];
export const ESTADOS_USUARIO = [
  { value: '1', label : 'Activo'},
  { value: '2', label : 'Suspendido'}
];

export const CALENDAR_LANG = {
  formatLocale: {
    firstDayOfWeek: 1,
    weekdaysMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
    monthsShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    placeholder: {
      date: 'Selecciona una Fecha',
      dateRange: 'Selecciona un Rango de Fechas'
    }
  },
}
