import Vue from 'vue'
import Router from 'vue-router'

import { getLoggedUser, refresh } from '@/app/views/auth/shared/services';
import { getPermisos } from '@/app/shared/global/services/global-service';

// Containers
const TheContainer = () => import('@/app/containers/TheContainer')

// Routes
import { homeRoutes } from '@/app/views/home';
import { authRoutes } from '@/app/views/auth';
import { vadosRoutes } from '@/app/views/vados';
import { visitasRoutes } from '@/app/views/visitas';
import { consultasRoutes } from '@/app/views/consultas';

import { usersRoutes } from '@/app/views/configuracion/usuarios';
import { variablesRoutes } from '@/app/views/configuracion/variables';
import { logActividadRoutes } from '@/app/views/configuracion/log-actividad';

import { ayuntamientosRoutes } from '@/app/views/ayuntamientos';
import { adminsRoutes } from '@/app/views/administradores';

Vue.use(Router)

const router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Inicio',
      component: TheContainer,
      children: [
        ...homeRoutes,
        {
          path: "gestion",
          redirect: "/gestion/vados",
          name: "",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            ...vadosRoutes,
            ...visitasRoutes,
            ...consultasRoutes,
          ]
        },
        {
          path: "configuracion",
          redirect: "/configuracion/usuarios",
          name: "Configuración",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            ...usersRoutes,
            ...variablesRoutes,
            ...logActividadRoutes
          ]
        },
        ...ayuntamientosRoutes,
        ...adminsRoutes,
      ]
    },
    ...authRoutes,
    {
      path: "*",
      redirect: "/"
    }
  ]
});


let checkValid = async () =>
await getLoggedUser()
  .then(response => response)
  .catch(e => e);


router.beforeEach(async (to, from, next) => {
  let token = localStorage.getItem("token");
  let user,
    expired = false;


  if (token) {
    let response = await checkValid();

    if (response.status === 'success') {

      const storageLoginTime = localStorage.getItem('login_time');
      // let storageExpiresIn = localStorage.getItem('expires_in');
      // 3600 segundos = 60 minutos = 1 hora

      let now = new Date();

      let dateExpiraSoon = new Date(storageLoginTime);

      let dateExpira = new Date(storageLoginTime);

      dateExpira.setHours(dateExpira.getHours() + 1);
      dateExpiraSoon.setMinutes(dateExpiraSoon.getMinutes() + 40 );

      if ( now >= dateExpiraSoon && now <= dateExpira) {
        // token próximo a caducar
        await refresh();
      }

      // token válido
      user = response.data;

    } else {
      localStorage.removeItem("token");
      token = null;
      expired = true;
    }
  }

  // to.matched.some(record => record.meta.auth
  if (to.meta && to.meta.auth == true) {
    if (token) {
      to.query.user = user;
      return await tienePermisos(to) ? next() : false;
    } else {
      next({
        path: "/login",
        query: {
          expired: expired
        }
      });
    }
  } else if (to.meta && to.meta.auth == false) {
    if (token) {
      next({
        path: "/",
        query: { user: user }
      });
    } else {
      return await tienePermisos(to) ? next() : false;
    }
  } else {
    return await tienePermisos(to) ? next() : false;
  }
});

let tienePermisos = async (to) => {
  if(!to.meta || !to.meta.entidad || !to.meta.tipo)
    return true;


  let permisos = await getPermisos();
  let tienePermiso = permisos.data[localStorage.getItem('user_rol')][to.meta.entidad][to.meta.tipo];


  return tienePermiso;
}

export default router;
