const DetalleUser = () => import('@/app/views/configuracion/usuarios/views/DetalleUser');
const ListadoUsers = () => import('@/app/views/configuracion/usuarios/views/ListadoUsers');
const NuevoUser = () => import('@/app/views/configuracion/usuarios/views/NuevoUser');


const usersRoutes = [
  {
    path: 'usuarios',
    redirect: '/configuracion/usuarios',
    name: 'Usuarios',
    component: {
      render(c) {
        return c('router-view');
      }
    },
    children: [
      {
        path: '',
        name: 'Listado',
        component: ListadoUsers,
        meta: {
          auth: true,
          entidad: 'usuarios',
          tipo: 'listar'
        }
      },
      {
        path: '/configuracion/usuarios',
        name: '',
        component: NuevoUser,
        meta: {
          auth: true,
          entidad: 'usuarios',
          tipo: 'crear'
        },
        children: [
          {
            path: '/configuracion/usuarios/nuevo',
            name: 'Crear',
            meta: {
              auth: true,
              entidad: 'usuarios',
              tipo: 'crear',
            }
          }
        ]
      },
      {
        path: '/configuracion/usuarios',
        name: '',
        component: DetalleUser,
        meta: {
          auth: true,
          entidad: 'usuarios',
          tipo: 'detalle'
        },
        children: [
          {
            path: '/configuracion/usuarios/:id',
            name: 'Detalle',
            meta: {
              auth: true,
              entidad: 'usuarios',
              tipo: 'detalle'
            }
          }
        ]
      }
    ]
  }
];

export default usersRoutes;
