const DetalleAdmin = () => import('@/app/views/administradores/views/DetalleAdmin');
const ListadoAdmins = () => import('@/app/views/administradores/views/ListadoAdmins');
const NuevoAdmin = () => import('@/app/views/administradores/views/NuevoAdmin');


const adminsRoutes = [
  {
    path: 'administradores',
    redirect: '/administradores',
    name: 'Administradores',
    component: {
      render(c) {
        return c('router-view');
      }
    },
    children: [
      {
        path: '',
        name: 'Listado',
        component: ListadoAdmins,
        meta: {
          auth: true,
          entidad: 'administradores',
          tipo: 'listar'
        }
      },
      {
        path: '/administradores',
        name: '',
        component: NuevoAdmin,
        meta: {
          auth: true,
          entidad: 'administradores',
          tipo: 'crear'
        },
        children: [
          {
            path: '/administradores/nuevo',
            name: 'Crear',
            meta: {
              auth: true,
              entidad: 'administradores',
              tipo: 'crear',
            }
          }
        ]
      },
      {
        path: '/administradores',
        name: '',
        component: DetalleAdmin,
        meta: {
          auth: true,
          entidad: 'administradores',
          tipo: 'detalle'
        },
        children: [
          {
            path: '/administradores/:id',
            name: 'Detalle',
            meta: {
              auth: true,
              entidad: 'administradores',
              tipo: 'detalle'
            }
          }
        ]
      }
    ]
  }
];

export default adminsRoutes;
