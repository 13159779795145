const Dashboard = () => import("@/app/views/home/views/Dashboard");

const homeRoutes = [
  {
    path: "dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      auth: true
    }
  },
];

export default homeRoutes;
